import smoothscroll from 'smoothscroll-polyfill';

/**
 * Polyfill the smooth scroll behaviour
 * 
 * Required for smooth scrolling to work on browsers that do not natively support it, such as:
 *  - Internet Explorer 12+
 *  - Microsoft Edge 9+
 *  - Safari 6+
 * 
 * More info can be found here: https://www.npmjs.com/package/smoothscroll-polyfill
 */
smoothscroll.polyfill();

/**
 * Subscribes the content element to the scroll event.
 *
 * When the scroll event is fired, the {@link updateButtonDisplayEvent} function is called, updating the button display.
 *
 * Also, preemptively calls the {@link updateButtonDisplay} function to update the button display immediately.
 * @param {HTMLDivElement} content
 * @param {HTMLButtonElement} leftButton
 * @param {HTMLButtonElement} rightButton
 */
export function subscribeContent(content, leftButton, rightButton) {
    updateButtonDisplay(content, leftButton, rightButton);

    leftButton.addEventListener('click', scrollLeft.bind(null, content));
    rightButton.addEventListener('click', scrollRight.bind(null, content));

    content.addEventListener('scroll', updateButtonDisplayEvent.bind(null, leftButton, rightButton));

    const resizeObserver = new ResizeObserver(updateButtonDisplay.bind(null, content, leftButton, rightButton));
    resizeObserver.observe(content);
}

/**
 * Event callback function for updating the button display. Internally calls the {@link updateButtonDisplay} function.
 * @param {HTMLButtonElement} leftButton
 * @param {HTMLButtonElement} rightButton
 * @param {Event} event
 */
function updateButtonDisplayEvent(leftButton, rightButton, event) {
    updateButtonDisplay(event.target, leftButton, rightButton);
}

/**
 * Function for updating the button display.
 *
 * If the content is able to be displayed in its entirety without requiring scrolling, both the left and right buttons will be hidden.
 *
 * Otherwise, if the content is scrolled entirely to the left, the left button will be hidden.
 * The same occurs for the right button, if the content is scrolled entirely to the right
 * @param {HTMLDivElement} content
 * @param {HTMLButtonElement} leftButton
 * @param {HTMLButtonElement} rightButton
 */
function updateButtonDisplay(content, leftButton, rightButton) {
    const maxScroll = content.scrollWidth - content.offsetWidth;
    const scrollAmount = content.scrollLeft;

    leftButton.disabled = maxScroll <= 0 || scrollAmount <= 0;
    rightButton.disabled = maxScroll <= 0 || scrollAmount >= maxScroll;
}

/**
 * Scrolls the content element to the left, the scroll amount is determined by the width of the content element.
 *
 * Internally calls the {@link scroll} function.
 * @param {HTMLDivElement} content
 */
export function scrollLeft(content) {
    scroll(content, -content.offsetWidth);
}

/**
 * Scrolls the content element to the right, the scroll amount is determined by the width of the content element.
 *
 * Internally calls the {@link scroll} function.
 * @param {HTMLDivElement} content
 */
export function scrollRight(content) {
    scroll(content, content.offsetWidth);
}

/**
 * Scrolls the target element according to the given scroll amount (in pixels).
 * @param {HTMLElement} target
 * @param {number} scrollAmount
 */
function scroll(target, scrollAmount) {
    target.scroll(
        {
            left: target.scrollLeft + scrollAmount,
            behavior: 'smooth'
        }
    );
}

window.GameCarousel = {
    subscribeContent,
    scrollLeft,
    scrollRight
}
